import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { loginWithMicrosoft } from "@/utils/firebase";
import { cart } from "@/store/slices/cartSlice";
import { isEqual } from "lodash";
import EStateAsyncStatus from "@/constants/stateAsyncState";
import { thirdPartyLogin } from "@/service/customer";
import { notify } from "@/components/Shares/Notification";
import { TOKEN } from "@/constants/storeLocation";
import { put as storePut } from "@/utils/storageHelper";
import { login } from "@/store/slices/loginSlice";

const MicrosoftLogin = () => {
  const cartState = useAppSelector(cart);
  const dispatch = useAppDispatch();

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault;
    const res1 = await loginWithMicrosoft();

    if (res1.success) {
      if (
        isEqual(cartState.status, EStateAsyncStatus.succeeded) &&
        cartState.cart
      ) {
        const payload = {
          email: `${res1.user?.email}`,
          firstName: `${res1.user?.displayName}`,
          shoppingCartId: cartState.cart.id,
        };
        try {
          const response = await thirdPartyLogin(payload);
          if (!response.token) {
            if (Array.isArray(response.data.message)) {
              notify(response.data.message[0], "error");
            } else {
              notify(response.data.message, "error");
            }
          } else {
            storePut(TOKEN, response.token);
            dispatch(login());
            notify(`Welcome to Priceworth Furniture!`, "success");
          }
        } catch (e) {}
      }
    }
  };

  return (
    <button onClick={handleClick}>
      <div className="border p-2 size-12 flex items-center justify-center hover:shadow-card hover:border-icon transition-all duration-500">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 129 129"
          xmlSpace="preserve"
        >
          <style type="text/css">
            {`
        .st0{fill:#F25022;}
        .st1{fill:#7FBA00;}
        .st2{fill:#00A4EF;}
        .st3{fill:#FFB900;}
      `}
          </style>
          <path className="st0" d="M0,0h61.3v61.3H0V0z" />
          <path className="st1" d="M67.7,0H129v61.3H67.7V0z" />
          <path className="st2" d="M0,67.7h61.3V129H0V67.7z" />
          <path className="st3" d="M67.7,67.7H129V129H67.7V67.7z" />
        </svg>
      </div>
    </button>
  );
};

export default MicrosoftLogin;
